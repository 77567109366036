@import '../../../src/styles/helpers'

.root
    display: flex
    flex-direction: column
    margin-top: 30px
    +lg
        width: 100%
        padding-left: 0px
.footer
    width: 100%
    display: flex
    flex-direction: column
    align-items: stretch
    gap: 16px
    +lg
        width: 100%
        padding-left: 0px

.footerBanner
    width: 100%

    +lg
        width: 100%
        padding-left: 0px

.footerIconBox
    display: flex
    justify-content: flex-end
    padding: 16px
    gap: 30px
    padding-right: 32px
    background-color: #262424
    +xs
        justify-content: center
        padding-right: 0px

.footerIcon
    color: white
    font-size: 30px
    cursor: pointer

.footer_text
    text-align: center
    color: white
    // min-height: 50px
